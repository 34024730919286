import "@babel/polyfill";
import axios from "axios";
import uuid from "./helpers/uuid";

window.onload = checkToken;

function checkToken() {
    var hash = window.location.hash;
    if (hash.length > 1) {
        let splitHash = hash.split("#id_token=");
        if (splitHash.length > 1) {
            let id_token = splitHash[1];
            let nonce = window.localStorage.getItem("nonce");
            axios.post("/signin-oidc", { id_token, nonce })
                .then(response => {
                    //good
                    document.location.href = "/";
                })
                .catch(e => {
                    //Token validation failed
                    openIDPortal(e.response);
                });
        } else {
            //Redirected from login.kc, but invalid access
            openIDPortal({ data: "Unable to verify access. Please try again or contact your administrator if the problem persists.", status: "401", statusText: "Unauthorized" });
        }
    } else {
        //Validation not attempted, redirect to auth endpoint
        openIDAuth();
    }
}

function openIDAuth() {
    let nonce = uuid();
    window.localStorage.setItem("nonce", nonce);
    axios.post("/oid-redirect", { nonce })
        .then(response => {
            window.location.href = response.data.URL;
        }).catch(e => {
            console.error(e);
            openIDPortal({ data: "", status: "500", statusText: "Unspecified server error. Please try again or contact your administrator if the problem persists." });
        });
}

function openIDPortal(err) {
    let htmlBody = document.getElementById("app");
    let errorCodeSection = document.getElementById("errorCode");
    let errorMessageSection = document.getElementById("errorMessage");
    let errorDetailSection = document.getElementById("errorDetail");
    let returnLinkSection = document.getElementById("returnLink");
    axios.post("/oid-portal")
        .then(response => {
            htmlBody.style.visibility = "visible";
            errorCodeSection.innerHTML = err.status;
            errorMessageSection.innerHTML = err.statusText;
            errorDetailSection.innerHTML = err.data
            returnLinkSection.setAttribute("href", response.data.URL)
        }).catch(e => {
            htmlBody.style.visibility = "visible";
            errorCodeSection.innerHTML = "500";
            errorMessageSection.innerHTML = "Unspecified server error. Please contact your administrator";
            console.error(e);
        });
}